var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vx-row" }, [
    _c(
      "div",
      { staticClass: "vx-col w-full mb-base" },
      [
        _c(
          "vx-card",
          [
            _c("div", { staticClass: "vx-row" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-4 sm:mb-0 mb-2",
                      on: { click: _vm.addDepositOption },
                    },
                    [_vm._v("Add")]
                  ),
                ],
                1
              ),
            ]),
            _c("ag-grid-vue", {
              ref: "agGridTable",
              staticClass: "ag-theme-material w-100 my-4 ag-grid-table",
              attrs: {
                components: _vm.cellRendererComponents,
                columnDefs: _vm.columnDefs,
                defaultColDef: _vm.defaultColDef,
                rowData: _vm.deopositOptionsList,
                gridOptions: _vm.gridOptions,
                autoGroupColumnDef: _vm.autoGroupColumnDef,
                rowSelection: "single",
                pagination: true,
                context: _vm.context,
                suppressPaginationPanel: false,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }